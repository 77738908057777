import React, { useEffect, useState } from 'react';
import Header from './header';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/manageUsers.module.css';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users`);
                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }
                const data = await response.json();
                setUsers(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchUsers();
    }, []);

    const handleDelete = (userId) => {
        const confirmation = window.confirm(`Are you sure you want to delete user ID ${userId}?`);
        if (confirmation) {
            // Placeholder: Add delete logic here
            alert(`User ID ${userId} deleted!`);
        }
    };

    return (
        <div>
            <Header username={localStorage.getItem('username')} />
            <div className={styles.manageUsersContainer}>
                <h1 className={styles.heading}>Manage Users</h1>
                {error && <p className={styles.error}>{error}</p>}
				 <div className={styles.responsiveTable}>
                <table className={styles.userTable}>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Username</th>
                            <th>Gamertag</th>
                            <th>Admin</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user.user_id}>
                                <td>{user.user_id}</td>
                                <td>{user.username}</td>
                                <td>{user.gamertag || 'N/A'}</td>
                                <td>{user.is_admin ? 'Yes' : 'No'}</td>
                                <td>
                                    <button
                                        onClick={() => navigate(`/edit/${user.user_id}`)}
                                        className={styles.actionButton}
                                    >
                                        <i className="fas fa-edit"></i> Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(user.user_id)}
                                        disabled={user.is_admin}
                                        className={`${styles.actionButton} ${
                                            user.is_admin ? styles.disabledButton : ''
                                        }`}
                                    >
                                        <i className="fas fa-trash"></i> Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
				</div>
            </div>
        </div>
    );
};

export default ManageUsers;
