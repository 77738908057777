import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from '../styles/manageVideos.module.css';

const ManageVideos = () => {
    const [videos, setVideos] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const username = localStorage.getItem('username'); // Retrieve username from localStorage

    // Fetch videos from the backend
    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/videos`);
				if (!response.ok) {
					throw new Error('Failed to fetch videos');
				}
                const data = await response.json();
                setVideos(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchVideos();
    }, []);

    // Delete video
    const handleDelete = async (video_id) => {
        if (window.confirm('Are you sure you want to delete this video?')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/videos/${video_id}`, {
					method: 'DELETE',
				});

                if (!response.ok) {
                    throw new Error('Failed to delete video');
                }
                setVideos((prev) => prev.filter((video) => video.video_id !== video_id));
            } catch (err) {
                setError(err.message);
            }
        }
    };

    return (
        <div>
            <Header username={username} />

            <div className={styles.manageVideosContainer}>
                <h1 className={styles.heading}>Manage Videos</h1>
                {error && <p className={styles.error}>{error}</p>}
                <div className={styles.actionButtons}>
                    <button
                        onClick={() => navigate('/manage-categories')}
                        className={styles.actionButton}
                    >
                        Manage Categories
                    </button>
                    <button
                        onClick={() => navigate('/add-video')}
                        className={styles.actionButton}
                    >
                        Add Video
                    </button>
                </div>
				<div className={styles.responsiveTable}>
                <table className={styles.videoTable}>
                    <thead>
                        <tr>
                            <th>Thumbnail</th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Questions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {videos.map((video) => (
                            <tr key={video.video_id}>
                                <td>
                                    <img
                                        src={video.thumbnail || 'https://via.placeholder.com/100'}
                                        alt={video.title}
                                        className={styles.thumbnail}
                                    />
                                </td>
                                <td>{video.title}</td>
                                <td>{video.category || 'N/A'}</td>
                                <td>{video.total_questions}</td>
                                <td className={styles.actions}>
                                    <button
                                        onClick={() => navigate(`/edit-video/${video.video_id}`)}
                                        className={styles.actionButton}
                                    >
                                        <i className="fas fa-edit"></i> Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(video.video_id)}
                                        className={`${styles.actionButton} ${styles.deleteButton}`}
                                    >
                                        <i className="fas fa-trash"></i> Delete
                                    </button>
                                    <button
                                        onClick={() => navigate(`/add-questions/${video.video_id}`)}
                                        className={styles.actionButton}
                                    >
                                        <i className="fas fa-question"></i> Add/Edit Questions
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
				</div>
            </div>
        </div>
    );
};

export default ManageVideos;
