import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import styles from '../styles/editVideo.module.css'; // Import CSS module

const EditVideo = () => {
    const { video_id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        url: '',
        description: '',
        total_questions: 0,
        category: '',
        thumbnail: '',
    });
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

    useEffect(() => {
        const fetchVideo = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/videos/${video_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch video details');
                }
                const data = await response.json();
                setFormData(data);
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/categories`);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                setCategories(data);
            } catch (err) {
                setError('Failed to fetch categories');
            }
        };

        fetchVideo();
        fetchCategories();
    }, [API_BASE_URL, video_id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}/videos/${video_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to update video');
            }

            const data = await response.json();
            setSuccess(data.message || 'Video updated successfully!');
            setTimeout(() => navigate('/manage-videos'), 2000);
        } catch (err) {
            console.error(err);
            setError(err.message || 'An error occurred while updating the video');
        }
    };

    return (
        <div>
            <Header username={username} />

            <div className={styles.editVideoContainer}>
                <h1 className={styles.heading}>Edit Video</h1>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                <form onSubmit={handleSubmit} className={styles.form}>
					<div className={styles.formGroup}>
                        <label htmlFor="url" className={styles.label}>YouTube URL:</label>
                        <input
                            type="url"
                            id="url"
                            name="url"
                            value={formData.url}
                            onChange={handleInputChange}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="title" className={styles.label}>Title:</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="description" className={styles.label}>Description:</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            rows="3"
                            className={styles.textarea}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="total_questions" className={styles.label}>Total Questions:</label>
                        <input
                            type="number"
                            id="total_questions"
                            name="total_questions"
                            value={formData.total_questions}
                            onChange={handleInputChange}
                            required
                            min="0"
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="category" className={styles.label}>Category:</label>
                        <select
                            id="category"
                            name="category"
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                            className={styles.select}
                        >
                            <option value="">Select a category</option>
                            {categories.map((cat) => (
                                <option key={cat.category_id} value={cat.name}>
                                    {cat.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="thumbnail" className={styles.label}>Thumbnail URL:</label>
                        <input
                            type="url"
                            id="thumbnail"
                            name="thumbnail"
                            value={formData.thumbnail}
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Update Video</button>
                </form>
            </div>
        </div>
    );
};

export default EditVideo;
