import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './header';
import styles from '../styles/EditPage.module.css';

const EditPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const loggedInUserId = localStorage.getItem('user_id');
    const isProfileView = !userId; // True if this is the user's profile
    const targetUserId = isProfileView ? loggedInUserId : userId;

    const [formData, setFormData] = useState({
        username: '',
        gamertag: '',
        email: '',
        profile_picture: '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        created_at: '',
        rewards: [],
        last_login: '',
    });

    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/users/${targetUserId}`);
                if (!response.ok) throw new Error('Failed to fetch user data');
                const data = await response.json();
                setFormData((prev) => ({
                    ...prev,
                    username: data.username || '',
                    gamertag: data.gamertag || '',
                    email: data.email || '',
                    profile_picture: data.profile_picture || '',
                    created_at: data.created_at || '',
                    rewards: data.rewards || [],
                    last_login: data.last_login || '',
                }));
            } catch (err) {
                setError(err.message || 'An error occurred while fetching user data');
            }
        };

        fetchUserData();
    }, [targetUserId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword && formData.newPassword !== formData.confirmNewPassword) {
            setError('New passwords do not match.');
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('username', formData.username);
            formDataToSend.append('gamertag', formData.gamertag);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('last_login', formData.last_login);
            if (file) {
                formDataToSend.append('profile_picture_file', file);
            }

            if (formData.newPassword) {
                formDataToSend.append('currentPassword', formData.currentPassword);
                formDataToSend.append('newPassword', formData.newPassword);
            }

            const response = await fetch(`${API_BASE_URL}/users/${targetUserId}`, {
                method: 'PUT',
                body: formDataToSend,
            });

            if (!response.ok) throw new Error('Failed to save user data');
            const data = await response.json();
            setSuccess(data.message || 'Profile updated successfully!');
            setError('');
            setFormData((prev) => ({
                ...prev,
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            }));
        } catch (err) {
            setError(err.message);
            setSuccess('');
        }
    };

    return (
        <div>
            <Header username={localStorage.getItem('username')} />
            <div className={styles.container}>
                <h1>{isProfileView ? 'Your Profile' : 'Edit User'}</h1>

                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className={styles.formGroup}>
                        <label>Username:</label>
                        <input
                            type="text"
                            value={formData.username}
                            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Current Password:</label>
                        <input
                            type="password"
                            value={formData.currentPassword}
                            onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>New Password:</label>
                        <input
                            type="password"
                            value={formData.newPassword}
                            onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Confirm New Password:</label>
                        <input
                            type="password"
                            value={formData.confirmNewPassword}
                            onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Gamer Tag:</label>
                        <input
                            type="text"
                            value={formData.gamertag}
                            onChange={(e) => setFormData({ ...formData, gamertag: e.target.value })}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Profile Picture:</label>
                        {formData.profile_picture && (
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}${formData.profile_picture}`}

                                alt="Profile"
                                width="100"
                                height="100"
                            />
                        )}
                        <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    </div>
                    <div className={styles.formGroup}>
                        <label>Created At:</label>
                        <p>{formData.created_at ? new Date(formData.created_at).toLocaleString() : 'Not available'}</p>
                    </div>
                    <div className={styles.formGroup}>
                        <label>Last Login:</label>
                        <p>{formData.last_login ? new Date(formData.last_login).toLocaleString() : 'Not available'}</p>
                    </div>
                    <div className={styles.formGroup}>
                        <label>Earned Rewards:</label>
                        <div className={styles.rewardsContainer}>
                            {formData.rewards.length > 0 ? (
                                formData.rewards.map((reward, index) => (
                                    <div key={index} className={styles.rewardItem}>
                                        {reward.icon && (
                                            <img
                                                src={`${process.env.REACT_APP_BACKEND_URL}${reward.icon}`}

                                                alt={reward.name}
                                                width="50"
                                                height="50"
                                            />
                                        )}
                                        <span>{reward.name}</span>
                                    </div>
                                ))
                            ) : (
                                <p>No rewards earned yet.</p>
                            )}
                        </div>
                    </div>
				{error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                    <button type="submit" className={styles.submitButton}>
                        {isProfileView ? 'Update Profile' : 'Update User'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EditPage;
