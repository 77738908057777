import React, { useState, useEffect } from 'react';
import Header from './header';
import styles from '../styles/HostGame.module.css';
import connectSocket from '../utils/socket'; // Updated import
import { useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';


const HostGame = () => {
    const [videos, setVideos] = useState([]);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [sessionCode, setSessionCode] = useState(null);
    const [players, setPlayers] = useState([]);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(6);


    const navigate = useNavigate();
    const socket = connectSocket(); // Obtain the socket instance
	
	    // Fetch host details from localStorage
    const [hostDetails, setHostDetails] = useState({
        host_id: '',
        host_name: '',
        host_avatar: '',
		
    });
	

useEffect(() => {
    // Fetch host details only once since it does not depend on any dynamic state
    const host_id = localStorage.getItem('user_id');
    const host_name = localStorage.getItem('username');
    const host_avatar = localStorage.getItem('profile_picture') || '/images/default-avatar.jpg'; // Ensure fallback for missing avatar

    setHostDetails({ host_id, host_name, host_avatar });

    console.log('Host Details:', { host_id, host_name, host_avatar });
}, []); // Empty dependency array ensures this runs only once


    // Fetch videos and categories
    useEffect(() => {
        const fetchVideosAndCategories = async () => {
            try {
                const [videoRes, categoryRes] = await Promise.all([
                    fetch(`${API_BASE_URL}/videos`),
                    fetch(`${API_BASE_URL}/categories`),
                ]);

                if (!videoRes.ok || !categoryRes.ok) {
                    throw new Error('Failed to fetch videos or categories');
                }

                const videoData = await videoRes.json();
                const categoryData = await categoryRes.json();

                setVideos(videoData);
                setFilteredVideos(videoData);
                setCategories(['All', ...categoryData.map((cat) => cat.name)]);
            } catch (err) {
                setError(err.message || 'Failed to load data');
            }
        };

        fetchVideosAndCategories();
		// Load YouTube API script
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onerror = () => {
            console.error('Failed to load the YouTube API script.');
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // Handle category change
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1);

        if (category === 'All') {
            setFilteredVideos(videos);
        } else {
            setFilteredVideos(videos.filter((video) => video.category === category));
        }
    };

    // Toggle video selection
    const toggleVideoSelection = (video) => {
        setSelectedVideos((prev) => {
            if (prev.some((v) => v.video_id === video.video_id)) {
                return prev.filter((v) => v.video_id !== video.video_id);
            } else if (prev.length < 5) {
                return [...prev, video];
            } else {
                alert('You can select up to 5 videos only.');
                return prev;
            }
        });
    };

   // Create session
const createSession = async () => {
    try {
        const { host_id, host_name, host_avatar } = hostDetails;
		const username = localStorage.getItem('username') || host_name;

        if (selectedVideos.length === 0) {
            alert('Please select at least one video to host.');
            return;
        }
		
		const payload = {
        host_id,
        host_name: username, // Use username as player_name
        host_avatar,
        video_ids: selectedVideos.map((v) => v.video_id),
    };

    console.log('Sending payload:', payload);

        const response = await fetch(`${API_BASE_URL}/sessions/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error creating session:', errorData);
            throw new Error(errorData.error || 'Failed to create session');
        }

        localStorage.setItem('isHost', 'true');
        localStorage.setItem('selectedVideos', JSON.stringify(selectedVideos)); // Store videos in localStorage

        const data = await response.json();
        setSessionCode(data.session_id);

        // Wait briefly to ensure socket connection is stable
        setTimeout(() => {
            // Emit join session event for the host with all details included
            socket.emit('join-session', {
                session_id: data.session_id,
                player_id: host_id,
                player_name: host_name,
                player_avatar: host_avatar,
            });

            // Add the host to the players list (host details are known at this point)
            setPlayers([
                {
                    id: host_id,
                    name: host_name,
                    avatar: host_avatar,
                },
            ]);
        }, 1000); // 1-second delay to ensure socket stability
    } catch (err) {
        console.error(err);
        setError(err.message || 'Failed to create session');
    }
};


    // Start game
   const startGame = () => {
    navigate(`/game/${sessionCode}`, { state: { isHost: true } }); // Pass isHost as true
   };

    // Real-time player updates
    useEffect(() => {
        if (sessionCode) {
            // Listen for new players joining the session
            socket.on('player-joined', (player) => {
                console.log(`${player.name} joined the session.`);
                setPlayers((prev) => [...prev, player]); // Add the new player to the players list
            });

            // Cleanup listener on component unmount
            return () => {
                socket.off('player-joined');
            };
        }
    }, [sessionCode]);

    // Pagination logic
    const paginatedVideos = filteredVideos.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const totalPages = Math.ceil(filteredVideos.length / pageSize);

    const handlePageChange = (direction) => {
        setCurrentPage((prev) => prev + direction);
    };

    return (
        <div>
            <Header username={localStorage.getItem('username')} />
            <div className={styles.hostGame}>
                {!sessionCode ? (
                    <>
                        <h1>Host a Game</h1>
				 <div className={styles.pagination}>
                            <button
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(-1)}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(1)}
                            >
                                Next
                            </button>
                        </div>
                        {error && <p className={styles.error}>{error}</p>}

                        <select
                            value={selectedCategory}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            className={styles.filterDropdown}
                        >
                            {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>

                        <ul className={styles.videoList}>
                            {paginatedVideos.map((video) => (
                                <li key={video.video_id} className={styles.videoItem}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedVideos.some((v) => v.video_id === video.video_id)}
                                            onChange={() => toggleVideoSelection(video)}
                                        />
                                        <div className={styles.videoThumbnailContainer}>
                                            <img
                                                src={video.thumbnail}
                                                alt={video.title}
                                                className={styles.videoThumbnail}
                                            />
                                        </div>
                                        <div className={styles.videoDetails}>
                                            <h3>{video.title}</h3>
                                            <p>{video.description.slice(0, 50)}...</p>
                                        </div>
                                    </label>
                                </li>
                            ))}
                        </ul>

                        <button className={styles.creatGame} onClick={createSession} disabled={selectedVideos.length === 0}>
                            Create Game Session
                        </button>
                    </>
                ) : (
                    <>
                        <h1>Game Session Created</h1>
                        <p className={styles.sessionCode}>
                            Session Code: <strong>{sessionCode}</strong>
                        </p>

                        <h3>Selected Videos</h3>
                        <ul className={styles.videoList}>
                            {selectedVideos.map((video) => (
                                <li key={video.video_id} className={styles.videoItem}>
                                    <div className={styles.videoThumbnailContainer}>
                                        <img
                                            src={video.thumbnail}
                                            alt={video.title}
                                            className={styles.videoThumbnail}
                                        />
                                    </div>
                                    <div className={styles.videoDetails}>
                                        <h3>{video.title}</h3>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <h3>Players Joined</h3>
                        <ul className={styles.videoList}>
                            {players.map((player, index) => (
                                <li key={index} className={styles.videoItem}>
                                    <div className={styles.videoThumbnailContainer}>
                                        <img
                                           src={
                                                player.avatar
                                                    ? `${BACKEND_BASE_URL}${player.avatar}`
                                                    : `/images/default-avatar.jpg`
                                            }
                                            alt={player.name}
                                            className={styles.videoThumbnail}
                                        />
                                    </div>
                                    <div className={styles.videoDetails}>
                                        <h3>{player.name}</h3>
                                    </div>
                                </li>
                            ))}
                        </ul>

                        <button className={styles.startButton} onClick={startGame} disabled={players.length === 0}>
                            Start Game
                        </button>
                    </>
                )}
            </div>
<div className={styles.subscribeContainer}>
                <h2>Support Us by Subscribing to Our YouTube Channel</h2>
                <div
                    className="g-ytsubscribe"
                    data-channelid="UCnuDDMdwNSfNwGeWBhXHQIw" // Replace with your Channel ID
                    data-layout="default"
                    data-count="default"
                ></div>

            </div>

        </div>
    );
};

export default HostGame;
