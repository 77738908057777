import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header'; // Import Header component
import styles from '../styles/setGame.module.css'; // Import the CSS Module

const SetGamePage = () => {
    const navigate = useNavigate();
    const storedUsername = localStorage.getItem('username'); // Retrieve username from localStorage
    const [username, setUsername] = useState(storedUsername || ''); // State for the username
    const [isGuest, setIsGuest] = useState(!localStorage.getItem('user_id')); // Determine if user is a guest

    useEffect(() => {
        // Save the username to localStorage whenever it changes
        if (username.trim()) {
            localStorage.setItem('username', username.trim());
        }
    }, [username]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const isButtonDisabled = !username.trim(); // Disable buttons if username is empty

    return (
        <div>
            {/* Add Header with the username from localStorage */}
            <Header username={storedUsername || 'Guest'} />
            <div className={styles.setGamePage}>
                <h1 className={styles.heading}>Set Game</h1>

                {/* Show the name input field only for guests */}
                {isGuest && (
                    <div className={styles.usernameContainer}>
                        <label htmlFor="username" className={styles.usernameLabel}>
                            Enter Your Name:
                        </label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder="Enter your name"
                            className={styles.usernameInput}
                        />
                    </div>
                )}

                <div className={styles.buttonContainer}>
                    {/* Profile button - disabled for guests */}
                    <button
                        className={styles.setGameButton}
                        onClick={() => navigate('/profile')}
                        aria-label="Go to Profile"
                        disabled={isGuest || isButtonDisabled}
                    >
                        <i className="fas fa-user"></i> Profile
                    </button>

                    {/* Play Single Game - disabled if no username */}
                    <button
                        className={styles.setGameButton}
                        onClick={() => navigate('/video-selection')}
                        aria-label="Play a Single Game"
                        disabled={isButtonDisabled}
                    >
                        <i className="fas fa-gamepad"></i> Play Single Game
                    </button>

                    {/* Host a Game button - disabled for guests or no username */}
                    <button
                        className={styles.setGameButton}
                        onClick={() => navigate('/host-game')}
                        aria-label="Host a Game"
                        disabled={isGuest || isButtonDisabled}
                    >
                        <i className="fas fa-users"></i> Host a Game
                    </button>

                    {/* Join a Game - disabled if no username */}
                    <button
                        className={styles.setGameButton}
                        onClick={() => navigate('/join-game')}
                        aria-label="Join a Game"
                        disabled={isButtonDisabled}
                    >
                        <i className="fas fa-handshake"></i> Join a Game
                    </button>
					 <button
                        className={`${styles.setGameButton} ${styles.disabledButton}`} // Add a specific style for disabled buttons
                        aria-label="View Leaderboards (Coming Soon)"
                        
                        title="Coming Soon!"
                    >
                        <i className="fas fa-trophy"></i> Leaderboard <br /> (Coming Soon)
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SetGamePage;
