import React, { useState } from 'react';
import styles from '../styles/ForgotPassword.module.css';
import { Link } from 'react-router-dom';


const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        try {
            setLoading(true);
            const response = await fetch(`${API_BASE_URL}/auth/forgot-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();
            setLoading(false);

            if (!response.ok) {
                throw new Error(data.error || 'Failed to send password reset email.');
            }

            setMessage('Password reset email sent! Check your inbox.');
        } catch (err) {
            setLoading(false);
            setError(err.message);
        }
    };

    return (
        <div className={styles.forgotPasswordContainer}>
            <h1>Forgot Password</h1>
            {message && <p className={styles.success}>{message}</p>}
            {error && <p className={styles.error}>{error}</p>}
            <form onSubmit={handleSubmit} className={styles.forgotPasswordForm}>
                <label>
                    Email Address:
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <button type="submit" disabled={loading}>
                    {loading ? 'Sending...' : 'Send Reset Email'}
                </button>
            </form>
			<div className={styles.backToLogin}>
                <Link to="/login">Back to Login</Link>
            </div>
        </div>
    );
};

export default ForgotPassword;
