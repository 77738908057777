import React, { useState } from 'react';

const ReportPage = () => {
    const [pdfUrl, setPdfUrl] = useState(null);

    const generateReport = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/view-report`);
            if (!response.ok) {
                throw new Error('Failed to fetch report');
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
        } catch (err) {
            console.error('Error generating report:', err.message);
        }
    };

    return (
        <div>
            <h1>Report Page</h1>
            <button onClick={generateReport}>Generate Report</button>
            {pdfUrl && (
                <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    title="Report Viewer"
                ></iframe>
            )}
        </div>
    );
};

export default ReportPage;
