import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from '../styles/videoSelection.module.css';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const VideoSelection = () => {
    const [videos, setVideos] = useState([]);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(6);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideosAndCategories = async () => {
            try {
                const [videoRes, categoryRes] = await Promise.all([
                    fetch(`${API_BASE_URL}/videos`),
                    fetch(`${API_BASE_URL}/categories`),
                ]);

                if (!videoRes.ok || !categoryRes.ok) {
                    throw new Error('Failed to fetch videos or categories');
                }

                const videoData = await videoRes.json();
                const categoryData = await categoryRes.json();

                setVideos(videoData);
                setFilteredVideos(videoData);
                setCategories(['All', ...categoryData.map((cat) => cat.name)]);
            } catch (err) {
                setError(err.message || 'Failed to load data');
            }
        };

        fetchVideosAndCategories();

        // Load YouTube API script
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js';
        script.async = true;
        script.onerror = () => {
            console.error('Failed to load the YouTube API script.');
        };
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1);

        if (category === 'All') {
            setFilteredVideos(videos);
        } else {
            setFilteredVideos(videos.filter((video) => video.category === category));
        }
    };
	

    const handleVideoSelect = async (videoId) => {
    localStorage.setItem('single_player', 'true');

    try {
		const playerId = localStorage.getItem('user_id');
		
        const response = await fetch(`${API_BASE_URL}/sessions/create-single-player`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
                video_id: videoId,
                player_name: localStorage.getItem('username'),
                player_id: playerId, // Send user_id only if available
            }),
        });

        const data = await response.json();
        if (!response.ok) throw new Error(data.error || 'Failed to create session.');

        const { session_id, guest_id } = data;

        // Set session_id and user_id in localStorage
        localStorage.setItem('user_id', guest_id);
        localStorage.setItem('session_id', session_id);

        // Navigate to the game page
        navigate(`/game/${session_id}`);
    } catch (err) {
        console.error('Error creating single-player session:', err.message);
        alert('Failed to start the game. Please try again.');
    }
};


    const paginatedVideos = filteredVideos.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const totalPages = Math.ceil(filteredVideos.length / pageSize);

    const handlePageChange = (direction) => {
        setCurrentPage((prev) => prev + direction);
    };

    return (
		
        <div>
            <Header username={localStorage.getItem('username')} />
            <div className={styles.videoSelection}>
				<div className={styles.pagination}>
                            <button
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(-1)}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(1)}
                            >
                                Next
                            </button>
                        </div>
                <h1>Select a Trivia Video</h1>
                {error ? (
                    <p className={styles.error}>{error}</p>
                ) : (
                    <>
                        <select
                            value={selectedCategory}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                            className={styles.filterDropdown}
                        >
                            {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>

                        <ul className={styles.videoList}>
                            {paginatedVideos.map((video) => (
                                <li
                                    key={video.video_id}
                                    className={styles.videoItem}
                                    onClick={() => handleVideoSelect(video.video_id)}
                                >
                                    <div className={styles.videoThumbnailContainer}>
                                        <img
                                            src={video.thumbnail}
                                            alt={video.title}
                                            className={styles.videoThumbnail}
                                        />
                                    </div>
                                    <div className={styles.videoDetails}>
                                        <h3>{video.title}</h3>
                                        <p>{video.description.slice(0, 50)}...</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </div>
            <div className={styles.subscribeContainer}>
                <h2>Support Us by Subscribing to Our YouTube Channel</h2>
                <div
                    className="g-ytsubscribe"
                    data-channelid="UCnuDDMdwNSfNwGeWBhXHQIw" // Replace with your Channel ID
                    data-layout="default"
                    data-count="default"
                ></div>
            </div>
        </div>
    );
};

export default VideoSelection;
