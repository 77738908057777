import { io } from 'socket.io-client';

let socket = null;

const connectSocket = () => {
    if (!socket) {
        // Use environment variable for backend URL
        const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

        socket = io(backendUrl, {
            transports: ['websocket', 'polling'],
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        });

        socket.on('connect', () => {
            console.log('Connected to server:', socket.id);
        });

        socket.on('connect_error', (err) => {
            console.error('Connection error:', err.message);
        });
    }
    return socket;
};

export default connectSocket;
