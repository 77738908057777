import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import Confetti from 'react-confetti';
import styles from '../styles/GamePage.module.css'; // Using GamePage styling for consistency

const SingleGame = () => {
    const { videoId } = useParams();
    const navigate = useNavigate();

    // States
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [score, setScore] = useState(0);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [incorrectAnswers, setIncorrectAnswers] = useState(0);
    const [timeLeft, setTimeLeft] = useState(10);
    const [isAnswered, setIsAnswered] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [gameEnded, setGameEnded] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(false);

    // Refs
    const playerRef = useRef(null);
    const timerRef = useRef(null);

    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
    const userId = localStorage.getItem('user_id') || `guest_${Date.now()}`;
    const playerName = localStorage.getItem('username') || 'Guest';

    const confettiSound = new Audio(`../sounds/confetti.mp3`);
    confettiSound.onerror = () => {
        confettiSound.src = `../sounds/confetti.ogg`;
    };
    confettiSound.preload = 'auto';

    const buttonPressSound = new Audio('../sounds/button.mp3');
    buttonPressSound.preload = 'auto';

    const wrongAnswerSound = new Audio('../sounds/wrong.mp3');
    wrongAnswerSound.preload = 'auto';

    const timeToSeconds = (time) => {
        if (!time) return 0;
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };

    const processedQuestions = new Set();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/videos/${videoId}/questions`);
                if (!response.ok) throw new Error('Failed to fetch questions');
                const data = await response.json();
                setQuestions(data);
            } catch (err) {
                console.error('Error fetching questions:', err.message);
            }
        };

        fetchQuestions();

        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, [videoId]);

    const startCountdown = () => {
        setTimeLeft(10);
        setIsAnswered(false);

        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setActiveQuestion(null);
                }
                return prev - 1;
            });
        }, 1000);

        timerRef.current = timer;
    };

    const handleAnswer = (choice) => {
        if (isAnswered) return;
        setIsAnswered(true);

        clearInterval(timerRef.current);

        const elapsedTime = 10 - timeLeft;
        const points = elapsedTime <= 10 ? Math.max(10 - elapsedTime, 0) : 0;

        if (choice === activeQuestion.correct_choice) {
            setScore((prevScore) => prevScore + points);
            setCorrectAnswers((prev) => prev + 1);
            buttonPressSound.play();

            if (elapsedTime <= 2) {
                setShowConfetti(true);
                confettiSound.play();
                setTimeout(() => setShowConfetti(false), 2000);
            }
        } else {
            setIncorrectAnswers((prev) => prev + 1);
            wrongAnswerSound.play();
        }

        setActiveQuestion(null);
    };

    const handlePlayPause = () => {
        if (playerRef.current) {
            if (isPlaying) {
                playerRef.current.pauseVideo();
            } else {
                playerRef.current.playVideo();
            }
            setIsPlaying((prev) => !prev);
        }
    };

    const toggleVideoVisibility = () => {
        setIsVideoVisible((prev) => !prev);
    };

    const toggleMute = () => {
        const player = playerRef.current;
        if (player) {
            if (isMuted) {
                player.unMute();
            } else {
                player.mute();
            }
            setIsMuted((prev) => !prev);
        }
    };

    const handleQuit = () => {
        if (window.confirm('Are you sure you want to quit?')) {
            navigate('/set-game');
        }
    };

    const onPlayerStateChange = (event) => {
        if (event.data === 1) {
            setIsPlaying(true);
            if (timerRef.current) clearInterval(timerRef.current);
            timerRef.current = setInterval(() => {
                const currentTime = playerRef.current.getCurrentTime();
                const question = questions.find(
                    (q) =>
                        currentTime >= timeToSeconds(q.start_time) &&
                        currentTime <= timeToSeconds(q.end_time) &&
                        !processedQuestions.has(q.question_id)
                );

                if (question) {
                    setActiveQuestion(question);
                    processedQuestions.add(question.question_id);
                    startCountdown();
                }
            }, 500);
        } else if (event.data === 0) {
            setIsPlaying(false);
            endGame();
        }
    };

    const submitScore = async () => {
        const payload = {
            video_id: videoId,
            points: score,
            questions_correct: correctAnswers,
            questions_incorrect: incorrectAnswers,
            player_name: playerName,
        };

        try {
            const response = await fetch(
                `${API_BASE_URL}/sessions/single/${userId}/score`,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Failed to submit score');
            console.log('Score submitted successfully:', data);
        } catch (err) {
            console.error('Error submitting score:', err.message);
        }
    };

    const fetchLeaderboard = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/sessions/single/${videoId}/scores`);
            if (!response.ok) throw new Error('Failed to fetch leaderboard');
            const data = await response.json();
            setLeaderboard(data || []);
        } catch (err) {
            console.error('Error fetching leaderboard:', err.message);
        } finally {
            setLoading(false);
        }
    };

    const endGame = async () => {
        if (gameEnded) return;
        setGameEnded(true);
        await submitScore();
        await fetchLeaderboard();
    };

    return (
        <div className={styles.gamePage}>
            {!gameEnded ? (
                <>
                    <div className={styles.scoreContainer}>
                        <h2>Score: {score}</h2>
                        <div className={styles.buttonContainer}>
                            <button onClick={toggleMute} className={styles.iconButton}>
                                {isMuted ? 'Unmute' : 'Mute'}
                            </button>
                            <button onClick={toggleVideoVisibility} className={styles.iconButton}>
                                {isVideoVisible ? 'Hide Video' : 'Show Video'}
                            </button>
                            <button onClick={handleQuit} className={styles.iconButton}>
                                Quit
                            </button>
                        </div>
                    </div>
                    <div className={`${styles.videoContainer} ${isVideoVisible ? '' : styles.hidden}`}>
                        <YouTube
                            videoId={videoId}
                            onStateChange={onPlayerStateChange}
                            onReady={(event) => (playerRef.current = event.target)}
                        />
                    </div>
                    {activeQuestion && (
                        <div className={styles.questionContainer}>
                            <h3>{activeQuestion.question_text}</h3>
                            <div>
                                <button onClick={() => handleAnswer('A')}>
                                    {activeQuestion.choice_a}
                                </button>
                                <button onClick={() => handleAnswer('B')}>
                                    {activeQuestion.choice_b}
                                </button>
                                <button onClick={() => handleAnswer('C')}>
                                    {activeQuestion.choice_c}
                                </button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.leaderboard}>
                    {loading ? (
                        <p>Loading leaderboard...</p>
                    ) : (
                        <>
                            <h2>Leaderboard</h2>
                            <ul>
                                {leaderboard.map((player, index) => (
                                    <li key={index}>
                                        {index + 1}. {player.player_name} - {player.score} points
                                    </li>
                                ))}
                            </ul>
                            <button onClick={() => navigate('/set-game')}>End Session</button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SingleGame;
