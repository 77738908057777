import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from '../styles/addVideo.module.css';

const AddVideo = () => {
    const [formData, setFormData] = useState({
        title: '',
        url: '',
        description: '',
        total_questions: 0,
        category: '',
        thumbnail: '',
    });
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/categories`);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                setCategories(data);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch categories');
            }
        };

        fetchCategories();
    }, [API_BASE_URL]);

    const fetchVideoDetails = async (url) => {
        try {
            const response = await fetch(`${API_BASE_URL}/video-details?url=${encodeURIComponent(url)}`);
            if (!response.ok) {
                throw new Error('Failed to fetch video details');
            }

            const data = await response.json();
            setFormData((prev) => ({
                ...prev,
                title: data.title || '',
                description: data.description || '',
                thumbnail: data.thumbnail || '',
            }));
        } catch (err) {
            console.error(err);
            setError('Failed to fetch video details');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        if (name === 'url') {
            setError('');
            fetchVideoDetails(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_BASE_URL}/videos`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (!response.ok) {
                throw new Error('Failed to add video');
				console.log('Submitting formData:', formData);
            }

            const data = await response.json();
            setSuccess(data.message || 'Video added successfully!');
            setTimeout(() => navigate('/manage-videos'), 2000);
        } catch (err) {
            console.error(err);
            setError(err.message || 'An error occurred while adding the video');
        }
    };

    return (
        <div>
            <Header username={username} />

            <div className={styles.addVideoContainer}>
                <h1 className={styles.heading}>Add New Video</h1>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                <form onSubmit={handleSubmit} className={styles.form}>
		              <div className={styles.formGroup}>
                        <label htmlFor="url" className={styles.label}>YouTube URL:</label>
                        <input
                            type="url"
                            id="url"
                            name="url"
                            value={formData.url}
                            onChange={handleInputChange}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="title" className={styles.label}>Title:</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            required
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="description" className={styles.label}>Description:</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            rows="3"
                            className={styles.textarea}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="total_questions" className={styles.label}>Total Questions:</label>
                        <input
                            type="number"
                            id="total_questions"
                            name="total_questions"
                            value={formData.total_questions}
                            onChange={handleInputChange}
                            required
                            min="0"
                            className={styles.input}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="category" className={styles.label}>Category:</label>
                        <select
                            id="category"
                            name="category"
                            value={formData.category}
                            onChange={handleInputChange}
                            required
                            className={styles.select}
                        >
                            <option value="">Select a category</option>
                            {categories.map((cat) => (
                                <option key={cat.category_id} value={cat.name}>
                                    {cat.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="thumbnail" className={styles.label}>Thumbnail URL:</label>
                        <input
                            type="url"
                            id="thumbnail"
                            name="thumbnail"
                            value={formData.thumbnail}
                            onChange={handleInputChange}
                            className={styles.input}
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Add Video</button>
                </form>
            </div>
        </div>
    );
};

export default AddVideo;
