import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/header.module.css';

const Header = ({ username }) => {
    const navigate = useNavigate();
	const isAdmin = localStorage.getItem('is_admin') === '1'; // Check if user is admin

    const handleLogout = () => {
        localStorage.removeItem('token'); // Clear the token from localStorage
        localStorage.removeItem('user_id'); // Clear user-specific data for guests
        navigate('/login'); // Redirect to the login page
    };

    const isGuest = username === 'Guest';

    return (
        <header className={styles.header}>
            <div className={styles.headerContent}>
                <h1 className={styles.logo} onClick={() => navigate('/set-game')}>Trivia Vid</h1>
                <div className={styles.headerUserInfo}>
                    {username && <p>Welcome, {username}</p>}
                    <div className={styles.headerUserButtons}>
						<button
                        onClick={() => navigate('/set-game')}
                        className={styles.setGameButton}
                    >
                        Set Game
                    </button>
					{isAdmin && (
                        <button
                            onClick={() => navigate('/admin')}
                            className={styles.dashboardButton}
                        >
                            Dashboard
                        </button>
                    )}
                    <button
                        onClick={handleLogout}
                        className={styles.logoutButton}
                    >
                        {isGuest ? 'Return to Login' : 'Logout'}
                    </button></div>
                </div>
            </div>
        </header>
    );
};

export default Header;
