import React from 'react';
import Header from './header'; // Ensure the path is correct
import styles from '../styles/admin.module.css'; // Import CSS Module
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const navigate = useNavigate();
    const username = localStorage.getItem('username') || 'Admin'; // Fallback to 'Admin'

    return (
        <div>
            {/* Add Header here */}
            <Header username={username} />

            <div className={styles.adminContainer}>
                <h1 className={styles.heading}>Admin Dashboard</h1>
                <p className={styles.description}>
                    Welcome to the admin panel! From here, you can manage users, videos, and other data.
                </p>

                <div className={styles.adminActions}>
                    <button onClick={() => navigate('/manage-users')} className={styles.actionButton}>
                        <i className="fas fa-users"></i> Manage Users
                    </button>
                    <button onClick={() => navigate('/manage-videos')} className={styles.actionButton}>
                        <i className="fas fa-video"></i> Manage Videos
                    </button>

                </div>
            </div>
        </div>
    );
};

export default Admin;

