import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from './header';
import styles from '../styles/addQuestions.module.css'; // Import CSS module

const AddQuestions = () => {
    const { video_id } = useParams();
    const [videoTitle, setVideoTitle] = useState('');
    const [questions, setQuestions] = useState([]);
    const [formData, setFormData] = useState({
        start_time: '',
        end_time: '',
        question_text: '',
        choice_a: '',
        choice_b: '',
        choice_c: '',
        correct_choice: '',
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const username = localStorage.getItem('username');

    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

    useEffect(() => {
        const fetchVideoDetails = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/videos/${video_id}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch video details');
                }
                const videoData = await response.json();
                setVideoTitle(videoData.title);
            } catch (err) {
                setError(err.message);
            }
        };

        const fetchQuestions = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/videos/${video_id}/questions`);
                if (!response.ok) {
                    throw new Error('Failed to fetch questions');
                }
                const data = await response.json();
                const sortedQuestions = data.sort((a, b) => {
                    const timeA = a.start_time.split(':').map(Number);
                    const timeB = b.start_time.split(':').map(Number);
                    return timeA[1] * 60 + timeA[2] - (timeB[1] * 60 + timeB[2]);
                });
                setQuestions(sortedQuestions);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchVideoDetails();
        fetchQuestions();
    }, [API_BASE_URL, video_id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const convertToTimeFormat = (time) => {
        const [minutes, seconds] = time.split(':').map(Number);
        if (isNaN(minutes) || isNaN(seconds) || minutes < 0 || seconds < 0 || seconds > 59) {
            throw new Error('Invalid time format. Use mm:ss format.');
        }
        return `00:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const startTime = convertToTimeFormat(formData.start_time);
            const endTime = convertToTimeFormat(formData.end_time);

            const response = await fetch(`${API_BASE_URL}/videos/${video_id}/questions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...formData,
                    start_time: startTime,
                    end_time: endTime,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to add question');
            }

            const data = await response.json();
            setSuccess(data.message || 'Question added successfully!');
            setQuestions((prev) => [
                ...prev,
                { ...formData, start_time: startTime, end_time: endTime, question_id: data.question_id },
            ]);
            setFormData({
                start_time: '',
                end_time: '',
                question_text: '',
                choice_a: '',
                choice_b: '',
                choice_c: '',
                correct_choice: '',
            });
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDelete = async (question_id) => {
        if (window.confirm('Are you sure you want to delete this question?')) {
            try {
                const response = await fetch(`${API_BASE_URL}/questions/${question_id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete question');
                }

                setQuestions((prev) => prev.filter((q) => q.question_id !== question_id));
            } catch (err) {
                setError(err.message);
            }
        }
    };

    return (
        <div>
            <Header username={username} />
            <div className={styles.container}>
                <h1 className={styles.heading}>Add Questions</h1>
                <h2 className={styles.subheading}>Video: {videoTitle}</h2>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="start_time">Start Time (mm:ss):</label>
                        <input
                            type="text"
                            id="start_time"
                            name="start_time"
                            value={formData.start_time}
                            onChange={handleInputChange}
                            placeholder="mm:ss"
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="end_time">End Time (mm:ss):</label>
                        <input
                            type="text"
                            id="end_time"
                            name="end_time"
                            value={formData.end_time}
                            onChange={handleInputChange}
                            placeholder="mm:ss"
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="question_text">Question:</label>
                        <input
                            type="text"
                            id="question_text"
                            name="question_text"
                            value={formData.question_text}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="choice_a">Choice A:</label>
                        <input
                            type="text"
                            id="choice_a"
                            name="choice_a"
                            value={formData.choice_a}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="choice_b">Choice B:</label>
                        <input
                            type="text"
                            id="choice_b"
                            name="choice_b"
                            value={formData.choice_b}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="choice_c">Choice C:</label>
                        <input
                            type="text"
                            id="choice_c"
                            name="choice_c"
                            value={formData.choice_c}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="correct_choice">Correct Choice:</label>
                        <select
                            id="correct_choice"
                            name="correct_choice"
                            value={formData.correct_choice}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Correct Answer</option>
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                        </select>
                    </div>
                    <button type="submit" className={styles.submitButton}>Add Question</button>
                </form>

                <h2 className={styles.existingQuestionsHeading}>Existing Questions</h2>
                <ul className={styles.questionList}>
                    {questions.map((q) => (
                        <li key={q.question_id} className={styles.questionItem}>
                            {q.start_time} - {q.end_time}: {q.question_text} (Correct: {q.correct_choice})
                            <button onClick={() => handleDelete(q.question_id)} className={styles.deleteButton}>
                                Delete
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AddQuestions;
