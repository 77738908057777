import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/Registration.module.css';
import { Link } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

const Registration = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match.');
        return;
    }

    const requestData = {
        username: formData.username,
        email: formData.email,
        password: formData.password,
    };

    try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Send JSON
            },
            body: JSON.stringify(requestData), // Convert object to JSON string
        });

        const data = await response.json();
        setLoading(false);

        if (!response.ok) {
            throw new Error(data.error || 'Registration failed.');
        }

        alert('Registration successful! Please log in.');
        navigate('/login');
    } catch (err) {
        setLoading(false);
        setError(err.message);
    }
};


    return (
        <div className={styles.registrationContainer}>
            <h1>Register</h1>
            {error && <p className={styles.error}>{error}</p>}
            <form className={styles.registrationForm} onSubmit={handleSubmit}>
                <label>
                    Username:
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Password:
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Confirm Password:
                    <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        required
                    />
                </label>
               
                <button type="submit" disabled={loading}>
                    {loading ? 'Registering...' : 'Register'}
                </button>
            </form>
			<div className={styles.backToLogin}>
                <Link to="/login">Back to Login</Link>
            </div>
        </div>
    );
};

export default Registration;
