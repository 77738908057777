// JavaScript Document
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import connectSocket from './utils/socket'; // Import the singleton socket utility
import { PlayerProvider } from './components/PlayerContext'; // Import the PlayerContext Provider

// Import components
import Login from './components/login';
import Admin from './components/admin';
import ManageUsers from './components/manageUsers';
import EditPage from './components/EditPage';
import ManageVideos from './components/ManageVideos';
import AddVideo from './components/AddVideo';
import EditVideo from './components/EditVideo';
import AddQuestions from './components/AddQuestions';
import ManageCategories from './components/ManageCategories';
import SetGamePage from './components/SetGamePage';
import SingleGame from './components/SingleGame';
import HostGame from './components/HostGame';
import VideoSelection from './components/VideoSelection';
import JoinGame from './components/JoinGame';
import GamePage from './components/GamePage';
import Registration from './components/Registration';
import ViewReports from './components/viewReports';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

const App = () => {
    const isHost = localStorage.getItem('isHost') === 'true';

    useEffect(() => {
        // Connect socket when the App component is mounted
        const socket = connectSocket();

        // Example event listener
        socket.on('event', (data) => {
            console.log('Received event:', data);
        });

        // NOTE: No socket disconnect here; we want it to stay connected globally.
        return () => {
            console.log('App component unmounted');
        };
    }, []); // Empty dependency array ensures it runs only once

    return (
        <PlayerProvider> {/* Wrap the entire app in the PlayerProvider */}
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/admin" element={<ProtectedRoute adminOnly={true}><Admin /></ProtectedRoute>} />
                    <Route path="/manage-users" element={<ProtectedRoute adminOnly={true}><ManageUsers /></ProtectedRoute>} />
                    <Route path="/edit/:userId" element={<ProtectedRoute><EditPage /></ProtectedRoute>} />
                    <Route path="/manage-videos" element={<ProtectedRoute adminOnly={true}><ManageVideos /></ProtectedRoute>} />
                    <Route path="/add-video" element={<ProtectedRoute adminOnly={true}><AddVideo /></ProtectedRoute>} />
                    <Route path="/edit-video/:video_id" element={<ProtectedRoute adminOnly={true}><EditVideo /></ProtectedRoute>} />
                    <Route path="/add-questions/:video_id" element={<ProtectedRoute adminOnly={true}><AddQuestions /></ProtectedRoute>} />
                    <Route path="/manage-categories" element={<ProtectedRoute adminOnly={true}><ManageCategories /></ProtectedRoute>} />
                    <Route path="/set-game" element={<SetGamePage />} />
					<Route path="/register" element={<Registration />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/profile" element={<EditPage />} />
                    <Route path="/single-game/:videoId" element={<SingleGame />} />
                    <Route path="/video-selection" element={<VideoSelection />} />
                    <Route path="/host-game" element={<ProtectedRoute><HostGame /></ProtectedRoute>} />
                    <Route path="/join-game" element={<JoinGame />} />
                    <Route path="/game/:sessionCode" element={<GamePage isHost={isHost} />} />
                    <Route path="/reports" element={<ProtectedRoute adminOnly={true}><ViewReports /></ProtectedRoute>} />
                    <Route path="/" element={<Login />} />
                </Routes>
            </Router>
        </PlayerProvider>
    );
	
	function App() {
    return (
        <PlayerProvider>
            <GamePage />
        </PlayerProvider>
    );
}
};

export default App;
