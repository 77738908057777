// GamePage.js
import React, { useEffect, useState, useRef } from 'react'; 
import { useNavigate, useParams } from 'react-router-dom';
import YouTube from 'react-youtube';
import Confetti from 'react-confetti';
import connectSocket from '../utils/socket'; // Import the function
import styles from '../styles/GamePage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute, faEye, faEyeSlash, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';
// Get the socket instance by calling connectSocket()
const socket = connectSocket();

// Convert time in hh:mm:ss format to seconds
const timeToSeconds = (time) => {
    if (!time) return 0;
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
};

const confettiSound = new Audio(`../sounds/confetti.mp3`);
confettiSound.onerror = () => {
    confettiSound.src = `../sounds/confetti.ogg`;
};
confettiSound.preload = 'auto';
const processedQuestions = new Set();

const GamePage = () => {
    const { sessionCode } = useParams();
	const isHost = localStorage.getItem('isHost') === 'true';
    const [questions, setQuestions] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [timer, setTimer] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [questionsCorrect, setQuestionsCorrect] = useState(0);
    const [questionsIncorrect, setQuestionsIncorrect] = useState(0);
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(10);
    const [isAnswered, setIsAnswered] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [gameEnded, setGameEnded] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [isVideoVisible, setIsVideoVisible] = useState(true); // Video visibility
    const [isMuted, setIsMuted] = useState(false);
    const [videoId, setVideoId] = useState('');
    const navigate = useNavigate();
    const buttonPressSound = new Audio('../sounds/button.mp3'); // Adjust path as needed
    buttonPressSound.preload = 'auto';
    const wrongAnswerSound = new Audio('../sounds/wrong.mp3'); // Adjust path as needed
    wrongAnswerSound.preload = 'auto';
    const [streak, setStreak] = useState(0); // Track correct streak
    const [flashType, setFlashType] = useState(null); // Control PNG flash type
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false); // Track if the video is currently playing
    const [playerMessages, setPlayerMessages] = useState([]);
    const [playerScores, setPlayerScores] = useState({});
    const [winner, setWinner] = useState(null); // Track the winner
	const [leaderboard, setLeaderboard] = useState([]);
	const [gamesWon, setGamesWon] = useState(0);
	const userId = localStorage.getItem('user_id'); // Ensure user is signed in
    const isMultiplayer = true; // Set based on session details or props
    const token = localStorage.getItem('token'); // JWT token if using authentication
	const [loading, setLoading] = useState(false);
	const isSinglePlayer = localStorage.getItem('single_player') === 'true';
	
	    // References
    const playerRef = useRef(null);
	const intervalRef = useRef(null);
    const overlayRef = useRef(null); // Create a ref for the overlay
	

    // Fetch selected videos from the server on mount
    useEffect(() => {
        const fetchSelectedVideos = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/videos`);
                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error fetching videos:', errorData.error);
                    throw new Error(errorData.error || 'Failed to fetch selected videos.');
                }
                const videos = await response.json(); // Expect an array of { video_id }
                setSelectedVideos(videos);
                if (videos.length > 0) {
                    setVideoId(videos[0].video_id);
                }
            } catch (err) {
                console.error('Error fetching selected videos:', err.message);
            }
        };

        fetchSelectedVideos();
    }, [sessionCode]);
	
	const fetchLeaderboard = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/scores`, {
            headers: {
                'Authorization': `Bearer ${token}` // Include token if using authentication
            },
        });

        const data = await response.json();

        if (response.ok) {
            setLeaderboard(Array.isArray(data) ? data : []);
        } else {
            throw new Error(data.error || 'Failed to fetch leaderboard.');
        }
    } catch (error) {
        console.error('Error fetching leaderboard:', error.message);
        setLeaderboard([]); // Ensure state is still set to an array
    }
};

	
	const sortedLeaderboard = Array.isArray(leaderboard) 
    ? leaderboard.sort((a, b) => b.score - a.score) 
    : []
	
    useEffect(() => {
        if (videoId) {

            setActiveQuestion(null);
            setIsAnswered(false);
            setTimeLeft(10);
            processedQuestions.clear();


            // Clean up any existing intervals
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [videoId]);

    useEffect(() => {
        // Fetch questions for the current video
        const fetchQuestions = async () => {
            if (videoId) {
                try {
                    const questionsResponse = await fetch(
                        `${API_BASE_URL}/videos/${videoId}/questions`
                    );
                    const questionsData = await questionsResponse.json();

                    if (questionsResponse.ok) {
                        setQuestions(questionsData || []);
            
                    } else {
                        console.error('Error fetching questions:', questionsData.error);
                    }
                } catch (err) {
                    console.error('Error fetching questions:', err);
                }
            }
        };

        fetchQuestions();
    }, [videoId]);

    useEffect(() => {
       const fetchSessionDetails = async () => {
        try {
            const sessionResponse = await fetch(`${API_BASE_URL}/sessions/${sessionCode}`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Include if using authentication
                }
            });
            const sessionData = await sessionResponse.json();

            if (sessionResponse.ok) {
                setVideoId(sessionData.video_id || '');

                const questionsResponse = await fetch(
                    `${API_BASE_URL}/videos/${sessionData.video_id}/questions`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}` // Include if using authentication
                        }
                    }
                );
                const questionsData = await questionsResponse.json();

                if (questionsResponse.ok) {
                    setQuestions(questionsData || []);
                } else {
                    console.error('Error fetching questions:', questionsData.error);
                }
            } else {
                console.error('Error fetching session details:', sessionData.error);
            }
        } catch (err) {
            console.error('Error fetching session details or questions:', err);
        }
    };

    fetchSessionDetails();
// Ensure 'socket.connected' is valid


        if (socket && socket.connected) {
            // Listen for the 'start-video' event from the host
            socket.on('start-video', () => {
                setIsPlaying(true);
                if (playerRef.current) {
                    playerRef.current.playVideo();
                } else {
                    console.error("Player reference is not set yet");
                }
            });

            // Listen for the 'pause-video' event from the host
            socket.on('pause-video', () => {
                setIsPlaying(false);
                if (playerRef.current) {
                    playerRef.current.pauseVideo();
                } else {
                    console.error("Player reference is not set yet");
                }
            });

            // Listen for next video event from the host
            socket.on('next-video', ({ nextVideoId, nextIndex }) => {

                setVideoId(nextVideoId);
                setCurrentVideoIndex(nextIndex);
                setGameEnded(false);

                setActiveQuestion(null);
                setIsAnswered(false);
                setTimeLeft(10);
                processedQuestions.clear();
            });

            // Listen for player leaving the session
            socket.on('player-left', (data) => {
                setPlayerMessages((prevMessages) => [...prevMessages, data.message]);
            });

            // Listen for session-ended event from the server
            socket.on('session-ended', ({ message }) => {
				const userId = localStorage.getItem('user_id');
				if (userId && userId.startsWith('guest_')) {

					localStorage.removeItem('user_id');
				}
                alert(message); // Notify the player that the session has ended
                navigate('/set-game'); // Redirect to /set-game
            });

            // Cleanup on component unmount
            return () => {
                socket.off('start-video');
                socket.off('pause-video');
                socket.off('next-video');
                socket.off('player-left');
                socket.off('session-ended');
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        }
    }, [sessionCode, token, socket.connected]);

const handleTogglePlayPause = () => {
    if (socket && socket.connected) {

        if (isPlaying) {
            // Pause video
            playerRef.current.pauseVideo();

            // Emit pause-video event
            socket.emit('pause-video', { session_id: sessionCode });
        } else {
            // Play video
            playerRef.current.playVideo();

            // Emit start-video event
            socket.emit('start-video', { session_id: sessionCode });
        }

        // Toggle isPlaying state
        setIsPlaying((prev) => !prev);
    } else {
        console.error("Socket not connected");
    }
};


    const startCountdown = () => {
        if (timer) clearInterval(timer); // Clear any previous timer
        setTimeLeft(10);
        setIsAnswered(false);
        setFeedback(null);

        const newTimer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(newTimer); // Ensure cleanup
                    setActiveQuestion(null);
                }
                return prev - 1;
            });
        }, 1000);

        setTimer(newTimer);
    };

    const onPlayerStateChange = (event) => {
        if (event.data === 1) { // Video is playing
			if (isHost || isSinglePlayer) {
            setIsPlaying(true);
        	}
			if (overlayRef.current) overlayRef.current.style.pointerEvents = 'auto';
            if (intervalRef.current) clearInterval(intervalRef.current);

            intervalRef.current = setInterval(() => {
                const player = event.target;
                const current = player.getCurrentTime();

                const validQuestions = questions.filter(
                    (q) =>
                        current >= timeToSeconds(q.start_time) &&
                        current <= timeToSeconds(q.end_time) &&
                        !processedQuestions.has(q.question_id)
                );

                validQuestions.forEach((question) => {

                    processedQuestions.add(question.question_id);
                    setActiveQuestion(question);
                    startCountdown();
                });

                if (player.getPlayerState() === 0 && !gameEnded) { // Video ended
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                }
            }, 500);
		} else if (event.data === 2) {
		if (isHost || isSinglePlayer) {
            setIsPlaying(false);
        	}
		if (overlayRef.current) overlayRef.current.style.pointerEvents = 'none';
        } else if (event.data === 0) { // Video ended
            setGameEnded(true); // Set gameEnded to true
            submitScore();
        }
			
    };

    const handleAnswer = (choice) => {
        if (isAnswered) return;
        setIsAnswered(true);

        clearInterval(timer);

        const elapsedTime = 10 - timeLeft;
        const points = elapsedTime <= 10 ? Math.max(10 - elapsedTime, 0) : 0;

        if (choice === activeQuestion.correct_choice) {
            setScore((prevScore) => prevScore + points);
            setQuestionsCorrect((prev) => prev + 1);
            buttonPressSound.play();
            setStreak((prev) => prev + 1);


            const newStreak = streak + 1;
            if (newStreak === 3) {
                triggerFlash('streak-3'); // Flash for 3 in a row
            } else if (newStreak === 7) {
                triggerFlash('streak-7'); // Flash for 7 in a row
            }

            if (elapsedTime <= 2) {
                setShowConfetti(true);
                confettiSound.play();
                setTimeout(() => setShowConfetti(false), 6000);
                setFeedback(`That was Quick! +${points} points`);
            } else if (elapsedTime <= 6) {
                setFeedback(`Correct! +${points} points`);
            } else {
                setFeedback(`You Need to be Faster! +${points} points`);
            }
        } else {
            setQuestionsIncorrect((prev) => prev + 1);
            setFeedback('Incorrect! 0 points');
            wrongAnswerSound.play();
            setStreak(0);
        }
    };
	
	 // Function to submit score at the end of the video
const submitScore = async () => {
    try {
        let userId = localStorage.getItem('user_id');
        if (!userId) {
            console.error('User ID not found. You must join a game before submitting a score.');
            return;
        }

        const payload = {
            video_id: videoId, // Use videoId from state
            points: score,
            questions_correct: questionsCorrect,
            questions_incorrect: questionsIncorrect,
            player_name: localStorage.getItem('username') || 'Guest',
        };

        const response = await fetch(
            `${API_BASE_URL}/sessions/${sessionCode}/player/${userId}/score`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            }
        );

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error || 'Failed to submit score');
        }

    } catch (error) {
        console.error('Error submitting score:', error.message);
    }
		showFinalScores();
};




useEffect(() => {
    if (socket) {
        socket.on('update-leaderboard', (updatedLeaderboard) => {
            setLeaderboard(updatedLeaderboard); // Update the state with the new leaderboard
        });

        return () => {
            socket.off('update-leaderboard'); // Cleanup the listener
        };
    }
}, [socket]);



    const handleNextVideo = () => {
        if (currentVideoIndex + 1 < selectedVideos.length) {
            const nextIndex = currentVideoIndex + 1;
            setCurrentVideoIndex(nextIndex);
            const nextVideoId = selectedVideos[nextIndex].video_id;
            setVideoId(nextVideoId);
            setGameEnded(false);

            // Reset the playing state to false for the next video
            setIsPlaying(false);

            // Emit 'next-video' event to all players
            socket.emit('next-video', { session_id: sessionCode, nextVideoId, nextIndex });
        }
    };

    const showFinalScores = async () => {
	setLoading(true); // Show loader
	await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
    try {
        const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/scores`);
        if (!response.ok) {
            throw new Error('Failed to fetch session scores.');
        }
        const data = await response.json();
        setLeaderboard(data); // Assume `leaderboard` is a state to store player scores
    } catch (error) {
        console.error('Error fetching scores:', error.message);
    } finally {
        setLoading(false); // Hide loader
    }
};

    const leaveGame = () => {
        if (window.confirm('Are you sure you want to end the session?')) {
            if (isHost) {
                const playerName = localStorage.getItem('username') || 'Player';
                localStorage.setItem('isHost', 'false');
                socket.emit('session-ended', { session_id: sessionCode });
                navigate('/set-game');
            } else {
                const playerName = localStorage.getItem('username') || 'Player';
                socket.emit('leave-session', {
                    session_id: sessionCode,
                    player_id: socket.id,
                    player_name: playerName,
                });
				const userId = localStorage.getItem('user_id');
				if (userId && userId.startsWith('guest_')) {
					localStorage.removeItem('user_id');
				}
                navigate('/set-game');
            }
        }
    };

    const triggerFlash = (type) => {
        setFlashType(type); // Set the flash type
        setTimeout(() => setFlashType(null), 4000); // Hide flash after 2 seconds
    };
	
     // Function to end the game (Host only)
    const endGame = async () => {
        if (gameEnded) return; // Prevent multiple calls
        setGameEnded(true);

        try {
            const userId = localStorage.getItem('user_id');
            const response = await fetch(`${API_BASE_URL}/game-sessions/end`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Include if using authentication
                },
                body: JSON.stringify({ user_id: userId, session_id: sessionCode }),
            });

            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.error || 'Failed to end game session.');
            }

            alert(data.message);
            setWinner(data.winner); // Assuming the server returns the winner
            navigate('/set-game'); // Redirect to another page
        } catch (err) {
            console.error('Error ending game:', err.message);
            alert('Failed to end game. Please try again.');
        }
    };

	
	const endGameSession = async () => {
		if (window.confirm('Are you sure you want to end the session?')) {
			endSessionAndDetermineWinner()
			
			const userId = localStorage.getItem('user_id');
			
            if (userId && userId.startsWith('guest_')) {
                localStorage.removeItem('user_id'); // Clear guest ID from localStorage
            }
			
			try {
				const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/end`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
				});

				if (!response.ok) {
					throw new Error('Failed to end session.');
				}

				const data = await response.json();

				// Notify players via socket
				socket.emit('session-ended', { session_id: sessionCode });

				// Redirect the host to the set game page
				navigate('/set-game');
			} catch (err) {
				console.error('Error ending session:', err.message);
				alert('Failed to end session. Please try again.');
			}
		}
	};

	
const endSessionAndDetermineWinner = async () => {
    try {
		
		// Check if it's a single-player session
        const isSinglePlayer = localStorage.getItem('single_player') === 'true';
        if (isSinglePlayer) {
            return; // Exit the function early
        }

        // Step 1: Prepare headers
        const headers = {};
        const token = localStorage.getItem('token'); // Retrieve token if it exists
        if (token) {
            headers['Authorization'] = `Bearer ${token}`; // Include token for authenticated users
        }

        // Step 2: Fetch all session scores
        const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/scores`, { headers });
        const scores = await response.json();

        if (!response.ok || !Array.isArray(scores) || scores.length === 0) {
            console.error('Failed to fetch scores or no players available:', response.status, scores);
            alert('Unable to determine winner. No players found.');
            return;
        }

        // Step 3: Determine the winner (highest score)
        const topPlayer = scores.reduce((max, player) =>
            player.score > max.score ? player : max, { score: -Infinity }
        );

        if (!topPlayer.player_id) {
            console.error('No valid winner found in session scores:', scores);
            alert('Unable to determine winner. No valid player found.');
            return;
        }

        // Step 4: Update games_won for the winning player
        const updateResponse = await fetch(
            `${API_BASE_URL}/sessions/${sessionCode}/update-winner`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    winner_id: topPlayer.player_id,
                    video_id: selectedVideos[currentVideoIndex]?.video_id || null, // Ensure video_id is sent
                }),
            }
        );

        const updateData = await updateResponse.json();

        if (!updateResponse.ok) {
            console.error('Failed to update winner:', updateResponse.status, updateData.error);
            alert('Unable to update winner in database.');
            return;
        }

        alert(`Winner has been updated successfully: ${topPlayer.player_name}`);
    } catch (error) {
        console.error('Error determining winner and updating database:', error.message);
        alert('An error occurred while determining the winner. Please try again.');
    }
};
		const [hasStarted, setHasStarted] = useState(false); // New state variable


		useEffect(() => {
			if (activeQuestion && !hasStarted) {
				setHasStarted(true); // Set `hasStarted` to true when the first question is set
			}
		}, [activeQuestion, hasStarted]);



    const toggleMenu = () => setShowMenu((prev) => !prev);
    const toggleVideoVisibility = () => setIsVideoVisible((prev) => !prev);
    const toggleMute = () => {
        const player = playerRef.current;
        if (player) {
            isMuted ? player.unMute() : player.mute();
            setIsMuted((prev) => !prev);
        }
    };

    if (!videoId || !questions.length) {
        return <p>Loading game data...</p>;
    }

    return (
        <div className={styles.gamePage}>
            {!gameEnded ? (
                <>
                    <div className={styles.scoreContainer}>
                        <h2>Score: {score}</h2>
                        <div className={styles.buttonContainer}>
                            <button
                                onClick={toggleMute}
                                className={styles.iconButton}
                                aria-label={isMuted ? 'Unmute Video' : 'Mute Video'}
                                title={isMuted ? 'Unmute Video' : 'Mute Video'}
                            >
                                <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="lg" />
                            </button>
                            <button
                                onClick={toggleVideoVisibility}
                                className={styles.iconButton}
                                aria-label={isVideoVisible ? 'Hide Video' : 'Show Video'}
                                title={isVideoVisible ? 'Hide Video' : 'Show Video'}
                            >
                                <FontAwesomeIcon icon={isVideoVisible ? faEye : faEyeSlash} size="lg" />
                            </button>
                            <button
                                onClick={leaveGame}
                                className={styles.iconButton}
                                aria-label="Quit Game"
                                title="Quit Game"
                            >
                                <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                            </button>
                        </div>
                    </div>
                    <div className={`${styles.videoContainer} ${isVideoVisible ? '' : styles.hidden}`}>
                        <div className={styles.videoWrapper}>
                            <YouTube
                                videoId={videoId}
                                opts={{ playerVars: { autoplay: 0, controls: 0 } }}
                                onStateChange={onPlayerStateChange}
                                onReady={(event) => (playerRef.current = event.target)}
                            />
						<div ref={overlayRef} className={styles.videoOverlay}></div>
                        </div>
                    </div><div className={styles.questionWrapper}>
					{(isSinglePlayer || isHost || !localStorage.getItem('user_id')?.startsWith('guest_')) && (
						<>
							<button className={styles.toggleButton} onClick={handleTogglePlayPause}>
								{isPlaying ? 'Pause' : 'Start'}
							</button>
						</>
					)}
                    <div className={styles.playerMessages}>
                        {playerMessages.map((message, index) => (
                            <p key={index}>{message}</p>
                        ))}
                        {!isPlaying && !isHost && !isSinglePlayer && (
							<div>
								<p>Waiting for the host to start...</p>
							</div>
						)}
                    </div>
					{!hasStarted && (
						<div className={styles.instructions}>
							<i>
								<b>Get ready!</b>
								<br />
								Your choices will appear here. The quicker you answer, the more points you earn, so stay sharp to claim the throne as the trivia king or queen. Don’t forget to log in or sign up for full access to all features!
							</i>
						</div>
					)}
                    {showConfetti && (
                        <div className={`${styles.confettiWrapper} ${styles.fadeOut}`}>
                            <Confetti width={window.innerWidth} height={window.innerHeight} />
                        </div>
                    )}
                    {activeQuestion && (
                        <div className={styles.questionContainer}>
                            <h3>{activeQuestion.question_text}</h3>
                            <div className={styles.choices}>
                                <button onClick={() => handleAnswer('A')} disabled={isAnswered}>
                                    {activeQuestion.choice_a}
                                </button>
                                <button onClick={() => handleAnswer('B')} disabled={isAnswered}>
                                    {activeQuestion.choice_b}
                                </button>
                                <button onClick={() => handleAnswer('C')} disabled={isAnswered}>
                                    {activeQuestion.choice_c}
                                </button>
                            </div>
                            <div className={styles.feedbackContainer}>
                                {feedback && <p className={styles.feedback}>{feedback}</p>}
                            </div>
                        </div>
                    )}</div>
                </>
            ) : (
                <div className={styles.summaryContainer}>
                    {/* Game Summary */}

{loading ? (
            <div className="loader">
                <p>Fetching scores...</p>
                <div className="spinner"></div> {/* Add your spinner component or CSS */}
            </div>
        ) : (
				<div className={styles.leaderboardContainer}>
					<h2>Leaderboard</h2>
					<ul>
						{Array.isArray(leaderboard) && leaderboard.length > 0 ? (
									leaderboard.map((player, index) => (
										<div key={index}>
											<p>
												{index + 1}. {player.player_name} - {player.score} points
											</p>
										</div>
								))
							) : (
								<p>Updating Scores</p>
							)}
					</ul>
				</div>
			)}
                    {isHost && (
                        <div className={styles.buttonContainer}>
							{currentVideoIndex + 1 < selectedVideos.length && (
								<button onClick={handleNextVideo} className={styles.nextVideoButton}>
									Next Video
								</button>
							)}
                        </div>
                    )}
					{(isSinglePlayer || isHost || !localStorage.getItem('user_id')?.startsWith('guest_')) && (
								<button onClick={endGameSession} className={styles.endSessionButton}>
									End Session
								</button>

					)}
                </div>
            )}
        </div>
    );
};

export default GamePage;