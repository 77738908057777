import React, { useState } from 'react';
import authService from '../services/authService';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import styles from '../styles/login.module.css'; // Import the CSS Module


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await authService.login(username, password);
			
			            // Log the response to ensure the picture URL is there
            console.log('Login Response:', response);
			
            localStorage.setItem('user_id', response.user_id);
            localStorage.setItem('token', response.token);
            localStorage.setItem('username', response.username);
            localStorage.setItem('is_admin', response.is_admin);
			localStorage.setItem('profile_picture', response.profile_picture || '/images/default-avatar.jpg'); // Save profile picture or fallback
            window.location.href = response.is_admin ? '/admin' : '/set-game';
			
			
        } catch (err) {
            setError(err.message || 'Login failed');
        }
    };

    const handleGoogleSuccess = async (googleResponse) => {
        try {
            const response = await authService.googleLogin(googleResponse.credential);
			
			            // Log the response to ensure the picture URL is there
            console.log('Login Response:', response);
            localStorage.setItem('user_id', response.user_id);
            localStorage.setItem('token', response.token);
            localStorage.setItem('username', response.username);
            localStorage.setItem('is_admin', response.is_admin);
			localStorage.setItem('profile_picture', response.profile_picture || '/images/default-avatar.jpg'); // Save profile picture or fallback
            window.location.href = response.is_admin ? '/admin' : '/set-game';
        } catch (err) {
            setError(err.message || 'Google Sign-In failed');
        }
    };

    const handleGoogleError = () => {
        setError('Google Sign-In failed');
    };

    const handleEnterAsGuest = () => {
        localStorage.clear(); // Clear any existing localStorage data
        window.location.href = '/set-game'; // Redirect to /set-game
    };

    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <div className={styles.loginContainer}>
                <img
				  src="/images/logo-trivia-vid.png" // Path to your logo
				  alt="App Logo"
				  className={styles.logo}
				/>
                <form onSubmit={handleLogin}>
                    <label htmlFor="username" className={styles.label}>
                        Username:
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={styles.input}
                        required
                    />
                    <label htmlFor="password" className={styles.label}>
                        Password:
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={styles.input}
                        required
                    />
                    {error && <p className={styles.error}>{error}</p>}
                    <button type="submit" className={styles.button}>
                        Login
                    </button>
                </form>
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                />
                <button className={styles.guestButton} onClick={handleEnterAsGuest}>
                    Enter as Guest
                </button>
                <div className={styles.loginLinks}>
                    <a href="/forgot-password" className={styles.link}>
                        Forgot Password?
                    </a>
                    <a href="/register" className={styles.link}>
                        Register
                    </a>
                </div>
            </div>
        </GoogleOAuthProvider>
    );
};

export default Login;
