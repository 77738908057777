import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, adminOnly = false }) => {
    const token = localStorage.getItem('token');
    const isAdmin = localStorage.getItem('is_admin') === '1'; // Check admin status

    if (!token) {
        // If no token, redirect to login
        return <Navigate to="/login" />;
    }

    if (adminOnly && !isAdmin) {
        // If admin-only route but user is not admin, redirect to profile
        return <Navigate to="/404" />;
    }

    return children; // Render the protected component
};

export default ProtectedRoute;

