import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import styles from '../styles/joinGame.module.css';
import connectSocket from '../utils/socket';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';


const JoinGame = () => {
    const [sessionCode, setSessionCode] = useState('');
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [isUsernameSet, setIsUsernameSet] = useState(!!localStorage.getItem('username'));
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const socket = connectSocket();

    useEffect(() => {
        // Add event listeners
        socket.on('connect', () => {
            console.log(`Connected to the session with socket ID: ${socket.id}`);
        });

        socket.on('player-joined', (data) => {
            console.log(`${data.name} has joined the session.`);
        });

        return () => {
            // Clean up listeners on unmount
            socket.off('connect');
            socket.off('player-joined');
        };
    }, [socket]);

    const handleJoinGame = async () => {
    try {
        // Check if there is an existing guest user_id and remove it
        let storedUserId = localStorage.getItem('user_id');
        if (storedUserId && storedUserId.startsWith('guest_')) {
            console.log(`Removing guest user_id: ${storedUserId}`);
            localStorage.removeItem('user_id');
            storedUserId = null; // Ensure it's treated as null
        }

        // Generate or retrieve a new user ID
        const playerId = storedUserId || `guest_${Date.now()}`;
        localStorage.setItem('user_id', playerId); // Save the new user_id

        // Retrieve or generate the player name
        const playerName = localStorage.getItem('username') || username || `Guest_${Math.floor(Math.random() * 10000)}`;

        // Save the username if newly entered
        if (!localStorage.getItem('username') && username) {
            localStorage.setItem('username', username);
            setIsUsernameSet(true); // Update the state
        }

        const playerProfilePicture = localStorage.getItem('profile_picture') || null;

        console.log('Joining session with data:', {
            sessionCode,
            player_id: playerId,
            player_name: playerName,
            player_avatar: playerProfilePicture,
        });

        // Send request to join the session
        const response = await fetch(`${API_BASE_URL}/sessions/${sessionCode}/join`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                player_id: playerId,
                player_name: playerName,
                player_avatar: playerProfilePicture,
            }),
        });

        const data = await response.json();
        console.log('Join session response:', data);

        if (response.ok) {
            // Emit socket event for joining the session
            socket.emit('join-session', {
                session_id: sessionCode,
                player_id: playerId,
                player_name: playerName,
                player_avatar: playerProfilePicture,
            });

            // Navigate to the game page
            navigate(`/game/${sessionCode}`);
        } else {
            console.error('Failed to join session:', data.error);
            setError(data.error || 'Failed to join session. Please check the session code.');
        }
    } catch (err) {
        console.error('Error while trying to join the game:', err);
        setError('An unexpected error occurred. Please try again.');
    }
};


    return (
        <div>
            <Header username={username || 'Guest'} />
            <div className={styles.joinGame}>
                <h1 className={styles.heading}>Join a Game</h1>

                {!isUsernameSet && (
                    <div className={styles.inputContainer}>
                        <label htmlFor="username">
                            <i className="fas fa-user"></i> Your Name:
                        </label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your name"
                            className={styles.input}
                        />
                    </div>
                )}

                <div className={styles.inputContainer}>
                    <label htmlFor="sessionCode">
                        <i className="fas fa-key"></i> Session Code:
                    </label>
                    <input
                        id="sessionCode"
                        type="text"
                        value={sessionCode}
                        onChange={(e) => setSessionCode(e.target.value.toUpperCase())}
                        placeholder="Enter session code"
                        className={styles.input}
                    />
                </div>
                <button
                    onClick={handleJoinGame}
                    disabled={!sessionCode || (!isUsernameSet && !username)}
                    className={styles.joinButton}
                >
                    <i className="fas fa-sign-in-alt"></i> Join Game
                </button>
                {error && <p className={styles.error}>{error}</p>}
            </div>
			<div className={styles.information}>
				To dive into the fun, you'll need a session code provided by a game Host to join the action. Want to take the reins and Host your own game? Simply register and log in to unlock the full power of hosting and bring the trivia challenge to life!
			</div>
        </div>
    );
};

export default JoinGame;
