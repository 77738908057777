// JavaScript Document
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api'; // Dynamic API URL

// Function to handle regular login
const login = async (username, password) => {
    const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
        throw new Error('Invalid credentials'); // Throw error if login fails
    }

    return response.json(); // Return JSON response if successful
};

// Function to handle Google Sign-In
const googleLogin = async (token) => {
    const response = await fetch(`${API_URL}/auth/google`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }), // Send the Google token to the backend
    });

    if (!response.ok) {
        throw new Error('Google Login failed'); // Throw error if login fails
    }

    return response.json(); // Return JSON response if successful
};

// Export all auth functions
export default { login, googleLogin };