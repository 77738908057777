import React, { useState, useEffect } from 'react';
import Header from './header';
import styles from '../styles/manageCategories.module.css';

const ManageCategories = () => {
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';


    // Fetch categories on load
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/categories`);
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                const data = await response.json();
                setCategories(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchCategories();
    }, [API_BASE_URL]);

    // Handle adding a category
    const handleAddCategory = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/categories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: categoryName }),
            });

            if (!response.ok) {
                throw new Error('Failed to add category');
            }

            const data = await response.json();
            setSuccess(data.message || 'Category added successfully!');
            setCategories((prev) => [...prev, { category_id: data.category_id, name: categoryName }]);
            setCategoryName(''); // Reset the input field
        } catch (err) {
            setError(err.message);
        }
    };

    // Handle deleting a category
    const handleDeleteCategory = async (category_id) => {
        if (window.confirm('Are you sure you want to delete this category?')) {
            try {
                const response = await fetch(`${API_BASE_URL}/categories/${category_id}`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error('Failed to delete category');
                }

                setCategories((prev) => prev.filter((cat) => cat.category_id !== category_id));
                setSuccess('Category deleted successfully!');
            } catch (err) {
                setError(err.message);
            }
        }
    };

    const username = localStorage.getItem('username');

    return (
        <div>
            <Header username={username} />
            <div className={styles.manageCategoriesContainer}>
                <h1 className={styles.heading}>Manage Categories</h1>
                {error && <p className={styles.error}>{error}</p>}
                {success && <p className={styles.success}>{success}</p>}
                <form onSubmit={handleAddCategory} className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="categoryName" className={styles.label}>
                            Category Name:
                        </label>
                        <input
                            type="text"
                            id="categoryName"
                            name="categoryName"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                            required
                            className={styles.input}
                        />
                    </div>
                    <button type="submit" className={styles.addButton}>
                        Add Category
                    </button>
                </form>

                <h2 className={styles.subheading}>Existing Categories</h2>
                <ul className={styles.categoryList}>
                    {categories.map((cat) => (
                        <li key={cat.category_id} className={styles.categoryItem}>
                            <span className={styles.categoryName}>{cat.name}</span>
                            <button
                                onClick={() => handleDeleteCategory(cat.category_id)}
                                className={styles.deleteButton}
                            >
                                <i className="fas fa-trash"></i> Delete
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ManageCategories;
